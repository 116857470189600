html{
    font-size: 14px;
}

@media(min-width: 768px){
    html{
        font-size: 16px;
    }
}

button.GoogleLoginBtn {
    justify-content: center;
    font-size: 0.9375rem !important;
}

button.GoogleLoginBtn span{
    font-weight: bold !important;
}

.MuiOutlinedInput-input.Mui-disabled{
    cursor: not-allowed;
}